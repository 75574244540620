import Evaluators from "@/view/assign/evaluators/list_evaluators.vue";
import Evaluator from "@/view/assign/evaluators/view_evaluators/view_evaluator.vue";

const routes = [
    {
        path: '/evaluators',
        name: 'assign_evaluators',
        component: Evaluators,
        props: {showMenu: true}
    },
    {
        path: '/assign/evaluator',
        name: 'assign_evaluator',
        component: Evaluator,
        props: {id: null, showMenu: true, otherTitle: 'evaluator'},
    },
    {
        path: '/evaluator/:id/:windowed',
        name: 'assign_evaluator_id',
        component: Evaluator,
        props: {id: null, windowed: true, showMenu: false, background: true, otherTitle: 'evaluator'},
    },
    {
        path: '/evaluator/:id/:view/:windowed',
        name: 'assign_evaluator_id_view',
        component: Evaluator,
        props: {id: null, view: true,  windowed: true, showMenu: false, background: true, otherTitle: 'evaluator'},
    },
    {
        path: '/evaluator/:id/:view/:assigned/:practice/:windowed',
        name: 'assign_evaluator_by_assign',
        component: Evaluator,
        props: {id: null, view: true, assigned: true, practice: null, windowed: true, showMenu: false, background: true, otherTitle: 'evaluator'},
    }
];

export default routes;