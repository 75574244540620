<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'users',
            }"
            :header="header"
            :data="getterListPermissionUsers.length === 0 ? [] : getterListPermissionUsers"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'permission_users_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="itemProp.show">
                    <router-link :to="{name: 'permission_users_id', params: {id: itemProp['id']}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_users_' + itemProp['id'],
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }"
                        />
                    </router-link>
                    <GhAction
                        :dataction="{
                            id: 'btn_delete_users_' + itemProp['id'],
                            text: $t('delete'),
                            icon: require('../../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','PermsUser', itemProp.id,{'name': itemProp.firstname, 'surnames': itemProp.surname, 'group': itemProp.group}, $t('delete_user'), $t('preparing_delete_user'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'permission_users'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_user',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_users',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterListPermissionUsers'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'firstname', sorting: true, urlRedirect: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'surnames', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'group', field: 'group', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                haveData: false,
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getListPermissionUsers', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>