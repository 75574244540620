import ConfigFinalPunctuation from "@/view/config/evaluators/final_punctuation/template/final_punctuation.vue";

const routes = [
    {
        path: '/config/evaluator/final-punctuation',
        name: 'config_final_punctuation',
        component: ConfigFinalPunctuation,
        props: {id: null, showMenu: true, otherTitle: 'final_punctuation'},
        children: [
            {
                path: '/config/evaluator/final-punctuation/:id',
                name: 'config_final_punctuation_id',
                component: ConfigFinalPunctuation,
                props: {id: null, showMenu: true, otherTitle: 'final_punctuation'},
            },
            {
                path: '/config/evaluator/final-punctuation/:id/:view',
                name: 'config_final_punctuation_id_view',
                component: ConfigFinalPunctuation,
                props: {id: null, view: true, showMenu: true, otherTitle: 'final_punctuation'},
            }
        ]
    }
];

export default routes;