<template>
    <div class="gh-popup-ellipsis-title" :key="'popup_evaluate_' + refreshAdvice">
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_advice',
                title: $t('hard_points_improvement_opportunities'),
                type: 'info',
                style: 'font-size: 20px; width: 1000px; min-width: 1000px; max-width: 1000px;overflow-y: auto !important;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
            ref="RefPopupAdvice"
        >
            <template v-slot:body>
                <form id="form_popup_hard_points">
                    <component v-bind:is="advice" :edit="edit"/>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton v-if="edit"
                    :datainput="{
                        id: 'button_save_popup_advice',
                        text: $t('accept'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="submitPopup()"
                />
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_advice',
                        text: $t('close'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="Global.closePopUp('popup_advice');refreshAdvice++;"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import advice from '@/view/shared/template/advice.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'popup_advice',
        props: ['edit','submit', 'id'],
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapGetters(['getterAdvice', 'getterQuestionnaireJointMap'])
        },
        data(){
            return{
                advice: advice,
                refreshAdvice: 0
            }
        },
        watch: {
            'getterAdvice'(){
                if(this.$refs.RefPopupAdvice.$el.children[0].childNodes[0].children[0].children[0] !== undefined) {
                    this.$refs.RefPopupAdvice.$el.children[0].childNodes[0].children[0].children[0].remove();
                    this.$refs.RefPopupAdvice.$el.children[0].childNodes[0].children[0].style.setProperty("background-color", "#1B82C5", "important");
                }
            }
        },
        methods: {
            async submitPopup(){
                const formData = new FormData(document.getElementById('form_popup_hard_points'));

                let hard_points = [];
                let improvement_opportunities = [];
                [...formData].forEach((item) => {
                    if(item[0] === 'hard_points[]'){
                        hard_points.push(parseInt(item[1]));
                    }
                    if(item[0] === 'improvement_opportunities[]'){
                        improvement_opportunities.push(parseInt(item[1]));
                    }
                });

                let params = {
                    'hard_points': hard_points,
                    'improvement_opportunities': improvement_opportunities
                }

                if(this.$parent.valorations['uuid_' + this.id]['selecteds'] === undefined){
                    this.$parent.valorations['uuid_' + this.id]['selecteds'] = [];
                }

                this.$parent.disabledSave = false;
                this.$parent.valorations['uuid_' + this.id]['selecteds'] = params;

                await this.getterQuestionnaireJointMap.data.forEach((i, k) => {
                    if(i.id === this.id){
                        this.$store.dispatch('changeSelectionMap', {
                            key: k,
                            param: params
                        }, {root: true});
                    }
                })

                this.Global.closePopUp('popup_advice');
                this.refreshAdvice++;
            }
        }
    }
</script>

<style>
    .gh-popup-ellipsis-title > div > div > header > .modal-header > .collapsible-title{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
    }
</style>