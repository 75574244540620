import ConfigfOtherValues from "@/view/config/evaluators/other_values/template/other_values.vue";

const routes = [
    {
        path: '/config/evaluator/other-values',
        name: 'config_other_values',
        component: ConfigfOtherValues,
        props: {id: null, showMenu: true, otherTitle: 'config_other_values'},
        children: [
            {
                path: '/config/evaluator/other-values/:id',
                name: 'config_other_values_id',
                component: ConfigfOtherValues,
                props: {id: null, showMenu: true, otherTitle: 'config_other_values'},
            },
            {
                path: '/config/evaluator/other-values/:id/:view',
                name: 'config_other_values_id_view',
                component: ConfigfOtherValues,
                props: {id: null, view: true, showMenu: true, otherTitle: 'config_other_values'},
            }
        ]
    }
];

export default routes;