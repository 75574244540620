<template>
    <div v-if="haveData">
        <GhNoDropSelector :datablock="{id: 'no_drop_selector_user',title: $t('user')}">
            <template v-slot:content>
                <form id="form_users">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_firstname',
                                        name: 'user[firstname]',
                                        style:'width: 10%',
                                        value: getterPermissionUser.firstname,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') +':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPermissionUser.firstname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('surnames') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_last_name',
                                        name: 'user[surname]',
                                        style: 'width: 20%',
                                        value: getterPermissionUser.surname,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surnames') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPermissionUser.surname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_email',
                                        name: 'user[email]',
                                        style: 'width: 20%',
                                        value: getterPermissionUser.email,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email')+':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPermissionUser.email}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="getFeeds.feed_groups_type_company.type === 1">
                                <label class="gh_text_field-label" :class="$root._route.params.view ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('group') + ($root._route.params.view ? ':' : '*')}}</label>
                                <div v-for="(group, keyGroup) in getFeeds.feed_groups_type_company.feed" :key="keyGroup">
                                    <GhChecks
                                        :datalabel="{
                                            text: '',
                                            style: '',
                                            class: '',
                                            id: ''
                                        }"
                                        :datainput="{
                                            disabled: $root._route.params.view,
                                            label_required: $t('group'),
                                            required: true,
                                            selected: getterPermissionUser.group !== undefined ? Object.keys(getterPermissionUser.group)[0] === keyGroup : false,
                                            type: 'checkbox',
                                            id: 'value_user_group',
                                            value: keyGroup,
                                            name: 'user[groups][]'
                                        }"
                                    />
                                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{group}}</label>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'user_group',
                                            class: 'T15_b',
                                            subtitle: $t('select_group') + ':',
                                            text: $t('group') + '*',
                                            label: $t('group'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px;width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'value_user_group',
                                            name: 'user[groups][]'
                                        }"
                                        :feed="getFeeds.feed_groups_type_company.feed"
                                        :selected_input="getterPermissionUser.group !== undefined ? getterPermissionUser.group : []"
                                        :disabled_input="getterPermissionUser.disabled_group !== undefined ? getterPermissionUser.disabled_group : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('group')+':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(getterPermissionUser.group).join(', ')}}</label>
                                </template>
                            </template>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_user_save',
                    text: $t('save'),
                }"
                type="submit"
                @click="submitUser"
            />
            <GhButton
                :datainput="{
                    id: 'btn_user_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState, mapGetters} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    export default {
        name: "user_template",
        components: {
            GhChecks,
            GhDataSelector,
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterPermissionUser'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedGroupsTypeCompany', '', {root: true});
                await this.$store.dispatch('getPermsUser', {
                    id: this.$root._route.params.id
                }, {root: true});

                this.haveData = true;
            }
        },
        methods: {
            async submitUser(){
                if(this.Global.checkRequired('form_users') === 'OK'){
                    const formData = new FormData(document.getElementById('form_users'));
                    this.$store.dispatch('setPermsUser', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #user_group_selector_label {
        width: 150px !important;
        text-align: right;
    }
</style>
