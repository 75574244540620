import ListEvaluators from "@/view/config/evaluators/list_evaluators.vue";

import CCAA from "./ccaa/route";
import ClubCategory from "./club_category/route";
import Languages from "./languages/route";
import TypeEvaluator from "./type_evaluator/route";
import ProfessionalCategory from "./professional_category/route";
import ProfessionalActivitySector from "./professional_activity_sector/route";
import ProfessionalExperienceSector from "./professional_experience_sector/route";
import TypeEvaluation from "./type_evaluation/route";
import EvaluatorModality from "./evaluator_modality/route";
import GradeExperience from "./grade_experience/route";
import ScoreRange from "./score_range/route";
import SealRange from "./seal_range/route";
import Channel from "./channel/route";
import ProfessionalExperience from "./professional_experience/route";
import FinalPunctuation from "./final_punctuation/route";
import OtherValues from "./other_values/route";
import ClubExperience from "./club_experience/route"

const route = [
    {
        path: '/config_evaluators',
        name: 'config_evaluators',
        component: ListEvaluators,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...CCAA,
    ...ClubCategory,
    ...Languages,
    ...TypeEvaluator,
    ...ProfessionalCategory,
    ...ProfessionalActivitySector,
    ...ProfessionalExperienceSector,
    ...TypeEvaluation,
    ...EvaluatorModality,
    ...GradeExperience,
    ...ScoreRange,
    ...SealRange,
    ...Channel,
    ...ProfessionalExperience,
    ...FinalPunctuation,
    ...OtherValues,
    ...ClubExperience
]

export default routes;