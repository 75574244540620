<template>
    <div v-if="haveData">
        <div class="gh-row-content" v-if="getterTypeExercise.responsible.view" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : ''">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'label_exercise_questionnaire_responsible',
                        label: $t('responsible') + (getterTypeExercise.responsible.required ? '*' : ''),
                        title: $t('responsible'),
                        text: $t('select_responsible') + ':',
                        name: 'exercise[questionnaire][responsible]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: getterTypeExercise.responsible.required,
                        checkparentdata: false,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :selected_input="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.responsible ? BaseCreationExerciseTable.exercise_data.questionnaire.responsible : []"
                    :items="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.responsible && BaseCreationExerciseTable.exercise_data.questionnaire.responsible[0] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.responsible[0].text : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhInputTextField
                        style="margin-top: -5px;"
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'exercise_questionnaire_statement',
                            name: 'exercise[questionnaire][statement]',
                            style: 'width: 600px',
                            value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.statement : '',
                        }"
                        maxlength="100"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('description'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_questionnaire_description',
                            name: 'exercise[questionnaire][description]',
                            value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.description : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.description : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('statement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_questionnaire_real_statement',
                            name: 'exercise[questionnaire][real_statement]',
                            value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.real_statement : '',
                            required: false,
                            edited: false
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('statement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.real_statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('substatement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_questionnaire_substatement',
                            name: 'exercise[questionnaire][substatement]',
                            value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.substatement : '',
                            required: false,
                            edited: false
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('substatement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.substatement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.information.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('information') + (getterTypeExercise.information.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_questionnaire_information',
                        name: 'exercise[questionnaire][information]',
                        value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.information : '',
                        required: getterTypeExercise.information.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.information : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.score_legend.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('score_legend') + (getterTypeExercise.score_legend.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_questionnaire_score_legend',
                        name: 'exercise[questionnaire][score_legend]',
                        value: BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.score_legend : '',
                        required: getterTypeExercise.score_legend.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('score_legend') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.questionnaire !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.score_legend : ''"></label>
            </template>
        </div>
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{''}}</div>
        </div>
        <template v-if="getterTypeExercise.possible_questions.length !== 0">
            <GhTableCommon
                style="margin-bottom: 10px;"
                :extratable="{
                    id: 'answers'
                }"
                :header="header"
                :data="getterTypeExercise.possible_questions"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field === 'question'">
                        <template v-if="itemProp.view">
                            {{itemProp.question}}
                        </template>
                        <template v-else>
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    required: labelProp.required,
                                    id: 'exercise_questionnaire_question_question_'+itemProp.id,
                                    name: 'exercise[questionnaire][question][question]['+itemProp.id+']',
                                    style: 'margin-right: 10px;',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.answer[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.answer[itemProp.id] : '',
                                }"
                            />
                        </template>
                    </div>
                    <div v-else-if="labelProp.field == 'punctuation'">
                        <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    type: 'int',
                                    required: labelProp.required,
                                    id: 'exercise_questionnaire_answer_punctuation_'+itemProp.id,
                                    name: 'exercise[questionnaire][question][punctuation]['+itemProp.id+']',
                                    style: 'margin-right: 10px;',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation[itemProp.id] : '',
                                }"
                            />
                        </template>
                        <template v-else>
                            {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.punctuation[itemProp.id] : ''}}
                        </template>
                    </div>
                </template>
            </GhTableCommon>
        </template>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhInputTextField
                    :datalabel="{
                        text: $t('evidence') + (getterTypeExercise.autoevaluation.checked ? '*' : ''),
                        style: '',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: getterTypeExercise.autoevaluation.checked,
                        id: 'exercise_questionnaire_punctutation_evidence',
                        name: 'exercise[questionnaire][punctuation][evidence]',
                        style: 'width: 50px',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.punctuation.evidence : '',
                        type: 'int'
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('evidence') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.punctuation.evidence : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_before_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_questionnaire_text_evidence_before_download',
                        name: 'exercise[questionnaire][text_evidence][before_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.before_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_before_download') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.before_download : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + (getterTypeExercise.evidence.required ? '*' :'')}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[questionnaire][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + ''}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[questionnaire][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        view: true,
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.template !== null && BaseCreationExerciseTable.exercise_data.questionnaire.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_after_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_questionnaire_text_evidence_after_download',
                        name: 'exercise[questionnaire][text_evidence][after_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.after_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_after_download') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.text_evidence.after_download : ''"></label>
            </template>
        </div>
        <template v-if="getterTypeExercise.feedback.view">
            <template v-for="(item,index) in getterTypeExercise.possible_questions">
                <template v-if="getterTypeExercise.type_answer === 4">
                    <div class="gh-row-content" :key="'feedback_' + index">
                        <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                            <GhTextarea
                                :datalabel="{
                                    text: $t('feedback_answer') + ' ' + item.id,
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                }"
                                :datainput="{
                                    id: 'exercise_questionnaire_answer_feedback_' + item.id,
                                    name: 'exercise[questionnaire][answer][feedback]['+item.id+'][0]',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] : '',
                                    required: false,
                                    edited: true,
                                    image: {
                                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                                    }
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_answer') + ' ' + item.id + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] : ''"></label>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;" :key="'section_line_' + index">
                        <div class="T15_b">{{$t('feedback_answer') + ' ' + item.id}}</div>
                    </div>
                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                        <div class="gh-row-content" :key="'feedback_0_' + index">
                            <GhTextarea
                                :datalabel="{
                                    text: labelAnswer[0],
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                }"
                                :datainput="{
                                    id: 'exercise_questionnaire_answer_feedback_' + item.id + '_0',
                                    name: 'exercise[questionnaire][answer][feedback]['+item.id+'][0]',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] : '',
                                    required: false,
                                    edited: true,
                                    image: {
                                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                                    }
                                }"
                            />
                        </div>
                        <div class="gh-row-content" :key="'feedback_1_' + index">
                            <GhTextarea
                                :datalabel="{
                                    text: labelAnswer[1],
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                }"
                                :datainput="{
                                    id: 'exercise_questionnaire_answer_feedback_' + item.id + '_1',
                                    name: 'exercise[questionnaire][answer][feedback]['+item.id+'][1]',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][1] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][1] : '',
                                    required: false,
                                    edited: true,
                                    image: {
                                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                                    }
                                }"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="gh-row-content" :key="'KEY48' + index">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{labelAnswer[0] + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][0] : ''"></label>
                        </div>
                        <div class="gh-row-content" :key="'KEY49' + index">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{labelAnswer[1] + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id] !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][1] !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.answer.feedback[item.id][1] : ''"></label>
                        </div>
                    </template>
                </template>
            </template>
        </template>
        <template v-if="(getterTypeExercise.evidence.view && getterTypeExercise.feedback.view) || (getterTypeExercise.conditions !== undefined && getterTypeExercise.conditions.length !== 0)">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_evidence'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_questionnaire_feedback_evidence',
                            name: 'exercise[questionnaire][feedback][evidence][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.feedback.evidence : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_evidence') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.feedback.evidence : ''"></label>
                </template>
            </div>
        </template>
        <template v-if="getterTypeExercise.not_apply.checked && getterTypeExercise.feedback.view">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_not_apply'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_questionnaire_feedback_evidence',
                            name: 'exercise[questionnaire][feedback][not_apply][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.feedback.not_apply : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_not_apply') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback !== undefined && BaseCreationExerciseTable.exercise_data.questionnaire.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.questionnaire.feedback.not_apply : ''"></label>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import GhTextarea from "fe-gh-textarea-lib";
import GhTableCommon from "fe-gh-table-common-lib";
import GhInputTextField from "fe-gh-inputtextfield-lib";

import {mapGetters, mapState} from "vuex";
import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
import GhEasyUpload from "fe-gh-easy-upload-lib";

export default {
    name: "type_questionnaire",
    components: {
        GhEasyUpload,
        GhTreeSelectorBlock,
        GhTextarea,
        GhTableCommon,
        GhInputTextField,
    },
    computed: {
        ...mapState(['BaseCreationExerciseTable', 'getFeeds']),
        ...mapGetters(['getterTypeExercise'])
    },
    data(){
        return {
            haveData: false,
            header: [
                {text: '', field: 'id', sorting: false, typeSort: 'int', style: 'min-width: 20px; max-width: 20px; width: 20px;'},
                {text: 'questions', field: 'question', field_component: this.$root._route.params.view || this.$root._route.params.type === 'enabled' ? false : true, required: true, style: 'margin-left: -5px; max-width: 10px; width: 85%; padding-right: 10px;'},
                {text: 'punctuation', field: 'punctuation', field_component: this.$root._route.params.view || this.$root._route.params.type === 'enabled' ? false : true, required: true, style: 'min-width: 60px; width: 10%; padding-right: 10px;'},
            ],
            labelAnswer:[]
        }
    },
    async beforeMount() {
        const token = localStorage.getItem("token");
        if (token) {
            if (this.getterTypeExercise.responsible.view) {
                await this.$store.dispatch('getFeedResponsibles', '', {root: true});
            }
            await this.$store.dispatch('getFeedTypeAnswers', '', {root: true});

            if (this.getterTypeExercise.type_answer !== 4) {
                var load_answer = this.getFeeds.feed_type_answers[this.getterTypeExercise.type_answer];
                load_answer = load_answer.split('/');

                load_answer.forEach((item) => {
                    this.labelAnswer.push(item.trim());
                });
            }

            this.haveData = true;
        }
    }
}
</script>

<style>
    #label_exercise_questionnaire_responsible_selector_label, #label_exercise_subquestion_questionnaire_responsible_selector_label{
        width: 150px;
        text-align: end;
    }

    #subquestion_exist_data_select_SUMMARY{
        display: none !important;
    }
</style>