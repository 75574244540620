import Vue from "vue";
import {API_URL, LoginUrl} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
import router from "../../routes/router"
import Global from "../Global";
import AuthService from "@/services/AuthService";
Vue.use(vuex);
export default {
    state: {
        tabs:[],
        username:'',
        password:'',
        error_cif:'',
        error_mail:'',
        email:'',
        error:'',
        errormailcif:'',
        email_recover:'',
        token: '',
        token_time: 0,
        initial_to_general: true,
        view_initial: false,
        environment: [],
        app_auth_type: '',
        refreshLogToken: false,
        company: ''
    },
    mutations:{
        LOAD_USERNAME(state,payload){
            state.username = payload;
            payload != '' ? localStorage.setItem('username',payload):localStorage.removeItem('username');
        },
        LOAD_ERROR: (state, payload) => (state.error = payload),
        LOAD_EMAIL_REMEMBER(state,payload){
            state.email_recover = payload;
        },
        loadNameCompany(state, payload){
            state.company = payload;
        },
        Close_Messages_Login(state){
            state.error_cif = '';
            state.error_mail = '';
            state.email_recover = '';
            state.errormailcif = '';
        },
        LOAD_ERROR_MAIL(state,payload){
            if(payload.code == 'cif') {
                state.error_cif = payload.msg;
            }
            if(payload.code == 'email') {
                state.error_mail = payload.msg;
            }
            if(payload.code=='cifemail'){
                let ErrorMsgMail = payload.msg.split(",");
                state.error_cif = ErrorMsgMail[0];
                state.error_mail = ErrorMsgMail[1];
            }
            if(payload.code==''){
                state.errormailcif = payload.msg;
            }
            state.error_mail = payload;
        },
        LOAD_TOKEN(state,payload){
            state.refreshLogToken = !state.refreshLogToken;
            state.token = payload.token;
        },
        LOAD_TOKEN_TIME(state,payload){
            state.token_time = payload;
        },
        loadTabsWithPerm(state,payload){
            state.tabs = payload;
        },
        loadEnvironmentData(state, payload) {
            state.environment = payload;
        },
        setAppAuthType(state,payload){
            state.app_auth_type = payload;
        },
        setInitialToGeneral(state,payload){
            state.initial_to_general = payload;
        },
        setViewInitial(state,payload){
            state.view_initial = payload;
        },
    },
    getters: {
        isLoggedIn(state) {
            const refreshLogToken = state.refreshLogToken; // eslint-disable-line no-unused-vars
            return !!localStorage.getItem('token');
        },
    },
    actions:{
        REGISTER(){
            router.push({name: 'register'});
        },
        COLABORATION(){
            router.push({name: 'colaboration'});
        },
        initialToGeneral(state,item){
            state.commit('setInitialToGeneral', item);
        },
        viewInitial(state,item){
            state.commit('setViewInitial', item);
        },
        async resetAppTabs(state){
            if(!state) return ;
            router.options.GhTabs = [
                {id: 1, value: 'todo'},
                {id: 2, value: 'my_itineraries'},
                {id: 3, value: 'company'},
                {id: 4, value: 'perms'},
                {id: 5, value: 'configuration'},
                {id: 6, value: 'base_creation'},
                {id: 7, value: 'create'},
                {id: 8, value: 'searcher'},
                {id: 9, value: 'actions'},
                {id: 10, value: 'evaluation'},
                {id: 11, value: 'tutorships'},
                {id: 12, value: 'billing'},
                {id: 13, value: 'assignment'},
                {id: 14, value: 'support'},
                {id: 15, value: 'gh_admin'},
                {id: 16, value: 'user_file'},
                {id: 17, value: 'clients'}
            ];
            router.options.GhSubTabAssign = [
                {id: 1, value: 'ongoing_programs', call: 'assign_ongoing_programs'},
                {id: 2, value: 'programs_associate', call: 'assign_programs_associate'},
                {id: 3, value: 'evaluators', call: 'assign_evaluators'}
            ];
            router.options.GhSubTabs = [
                {id:1, value:'Liderazgo', slug: 'quiz-liderazgo', call: 'initial_itinerary'},
                {id:2, value:'Estrategia', slug: 'quiz-estrategia', call: 'initial_itinerary'},
                {id:3, value:'Personas', slug: 'quiz-personas', call: 'initial_itinerary'},
                {id:4, value:'Alianzas y recursos', slug: 'quiz-alianza-recursos', call: 'initial_itinerary'},
                {id:5, value:'Procesos, productos y servicios', slug: 'quiz-procesos', call: 'initial_itinerary'},
                {id:6, value:'Indicadores y resultados', slug: 'quiz-indicadores-resultados', call: 'initial_itinerary'},
            ];
            router.options.GhSubTabsMyItinerary = [
                {id:1, value: 'contracted_programs', call: 'contracted_programs'},
                {id:2, value: 'recomended_programs', call: 'recomended_programs'},
                {id:3, value: 'all_programs', call: 'all_programs'},
                {id:4, value: 'assigned_users', call: 'assigned_users'},
            ];
            router.options.GhSubTabsSearch = [
                {id:1,value:'itineraries', call: 'result_itineraries'}
            ];
            router.options.GhSubTabsConfiguration = [
                {id: 1, value: 'company', call: 'config_company'},
                {id: 2, value: 'creation', call: 'config_creation'},
                {id: 3, value: 'benchmark', call: 'config_benchmark'},
                {id: 4, value: 'support', call: 'config_support'},
                {id: 5, value: 'referential', call: 'config_referential'},
                {id: 6, value: 'evaluators', call: 'config_evaluators'}
            ];
            router.options.GhSubTabsPerms = [
                {id: 1, value: 'groups', call: 'perms_groups'},
                // {id: 2, value: 'company', call: 'perms_company'},
                {id: 2, value: 'user', call: 'perms_user'},
                {id: 3, value: 'evaluators', call: 'perms_evaluators'},
            ];
            router.options.GhSubTabsBaseCreation = [
                {id: 1, value: 'contents', call: 'base_creation_support_material'},
                {id: 2, value: 'exercises', call: 'config_exercises'},
            ];
            router.options.GhSubTabsCreation = [];
        },
        async SET_REGISTER(state, item){
            let requestLogin = API_URL + 'user/company/add';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    router.push({name: 'login'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async SET_COLLABORATION(state, item){
            let requestLogin = API_URL + 'user/collaboration';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        if(item.from === 'assign'){
                            router.push({name: 'assign_evaluators'});
                        }else{
                            router.push({name: 'login'});
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTabsWithPerm(state){
            await axios.get(
                LoginUrl + "tabs"
            ).then(
                response => {
                    var enter = false;
                    response.data.configuracion_perms.forEach((item) => {
                        if(item.have_permission && !enter){
                            localStorage.setItem('TabItem', item.name);
                            enter = true;
                        }
                    });
                    state.commit('loadTabsWithPerm', response.data.configuracion_perms);
                }
            )
        },
        TO_LOGIN(){
            router.push({name: 'login'});
        },
        async setUserAuthData(state,auth_data){
            state.state.refreshLogToken = !state.state.refreshLogToken;
            state.commit("LOAD_TOKEN", auth_data);
            localStorage.setItem('user',auth_data.username);
            localStorage.setItem('token',auth_data.token);
            localStorage.setItem('expire_date', auth_data.expire_date);
        },
        async setTokenExpiration(state,auth_data){
            var dformat = '';
            const date = new Date();
            dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

            var diff =(new Date(auth_data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
            diff /= (60 * 60);
            state.commit("LOAD_TOKEN_TIME", diff);
        },
        async getEnvironmentStyles(state){
            await axios.get(
                API_URL + "user/company/info-environment"
            ).then(
                response => {
                    state.commit('loadEnvironmentData', response.data.data);
                }
            )
        },
        async GET_USER(state, credentials) {
            localStorage.clear();

            let requestLogin = LoginUrl + "auth/login"
            let formData = new FormData();
            formData.append("username", credentials.username);
            formData.append("password", credentials.password);
            formData.append("submit", "Submit");
            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if (response.data.data) {
                        state.commit("LOAD_TOKEN", response.data.data);
                        localStorage.setItem('user',response.data.data.username);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);

                        await state.dispatch('getLanguagesData');
                        await this.dispatch('getTabsWithPerm');
                        await this.dispatch('getTabsWithPerm');
                        this.dispatch('resetAppTabs');
                        await this.dispatch('getEnvironmentStyles');

                        if(response.data.data.questionary_done === 1){
                            console.log(localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config');
                            router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config'});
                        }else{
                            await axios.get(
                                API_URL + "initial/id"
                            ).then(
                                response_2 => {
                                    state.commit('loadNameCompany', response_2.data.data.company);
                                    router.push({name: 'initial_itinerary',params:{id: response_2.data.data.id, type: 'initial'}});
                                    this.dispatch('viewInitial',true);
                                }
                            )
                        }
                        state.commit("LOAD_ERROR", '');

                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);

                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                async error => {
                    let the_error = {error: {code: error.code, msg: error.error}};
                    state.commit("LOAD_ERROR", the_error);
                }
            )
        },
        CloseMessagesLogin(state){
            state.commit("Close_Messages_Login")
        },
        GET_REMEMBER_PASSWORD(state, credentials) {
            let requestLogin = LoginUrl + "rmbpwd"
            let formData = new FormData();
            formData.append("cif", credentials.cif);
            formData.append("email", credentials.email);
            formData.append("submit", "Submit");
            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.data.data) {
                        Global.openPopUp('rememeber_password_success');
                        state.commit("LOAD_EMAIL_REMEMBER", response.data.data.returnmsg)
                    } else if (response.data.error) {
                        if(response.data.error.code.Normpwd){
                            Global.openPopUp('remember_password_error');
                        }
                        state.commit("LOAD_ERROR_MAIL", response.data.error)
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}};
                    state.commit("LOAD_ERROR", the_error);
                    console.log(error.name);
                }
            )
        },
        async SET_LOGOUT(state){
            let requestLogin = LoginUrl + "auth/logout";

            try {
                const response = await axios.post(requestLogin, null, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.data.status == 200) {
                    state.commit("LOAD_TOKEN", "");
                    state.commit("LOAD_TOKEN_TIME", "");

                } else if (response.data.error) {
                    state.commit("LOAD_ERROR", response.data.error);
                }
                localStorage.clear();
                this.dispatch('resetAppTabs');
                this.dispatch('initialToGeneral',true);
                this.dispatch('viewInitial',false);
                if (response.data.logout_callback) {
                    window.location.replace(response.data.logout_callback);
                }else{
                    router.push({name: 'login'});
                }
                return;
            } catch (error) {
                let the_error = { error: { code: error.code, msg: error.error } };
                state.commit("LOAD_ERROR", the_error);
            }
        },
        refreshToken(state){
            let requestLogin = LoginUrl + "/auth/refresh"
            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if (response.data.data.token !== undefined) {
                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);
                        state.commit("LOAD_TOKEN", response.data.data);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);
                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}}
                    state.commit("LOAD_ERROR", the_error)
                }
            )
        },
        async set401(state){
            if(router.currentRoute.name !== 'login') {
                if (document.getElementById('popup_401') === null) {
                    await state.dispatch('acceptSet401');
                } else {
                    Global.openPopUp('popup_401');
                }
            }
        },
        async acceptSet401(state){
            state.commit("LOAD_TOKEN", "");
            state.commit("LOAD_TOKEN_TIME", "");
            localStorage.clear();
            const authParams = await AuthService.getAuthType();
            if (authParams?.auth_type === 'SSO') {
                let sso_config = await AuthService.getLogoutUrl();
                if(sso_config['logout_callback']){
                    window.location.replace(sso_config['logout_callback']);
                }
            }else{
                router.push({ name: 'login'});
            }
        },
        async changeUserPassword(state, items){
            let requestLogin = LoginUrl + "auth/change-password"
            let formData = new FormData();
            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    return response;
                },
                error => {
                    return error.response;
                }
            )
        },
        async setChangeLanguage(state, item){
            let requestLogin = LoginUrl + "auth/language"
            let formData = new FormData();
            formData.append("language", item.lang);
            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}}
                    console.log(the_error);
                }
            )
        }
    }
}
