import ConfigProfessionalExperience from "@/view/config/evaluators/professional_experience/template/professional_experience.vue";

const routes = [
    {
        path: '/config/evaluator/professional-experience',
        name: 'config_professional_experience',
        component: ConfigProfessionalExperience,
        props: {id: null, showMenu: true, otherTitle: 'professional_experience'},
        children: [
            {
                path: '/config/evaluator/professional-experience/:id',
                name: 'config_professional_experience_id',
                component: ConfigProfessionalExperience,
                props: {id: null, showMenu: true, otherTitle: 'professional_experience'},
            },
            {
                path: '/config/evaluator/professional-experience/:id/:view',
                name: 'config_professional_experience_id_view',
                component: ConfigProfessionalExperience,
                props: {id: null, view: true, showMenu: true, otherTitle: 'professional_experience'},
            }
        ]
    }
];

export default routes;