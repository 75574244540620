import Clients from "@/view/clients/list_clients.vue";
import Client from "@/view/clients/template/client.vue";

const routes = [
    {
        path: '/clients',
        name: 'clients',
        component: Clients,
        props: {showMenu: true},
    },
    {
        path: '/client/:id',
        name: 'client_id',
        component: Client,
        props: {id: null, showMenu: true},
    }
];

export default routes;