import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
import router from "@/routes/router";
Vue.use(vuex);

export default {
    state: {
        data: [],
        refreshData: false,
        sectionEvaluations: [],
        refreshSectionEvaluations: false,
        evaluations: [],
        refreshEvaluations: false,
        results: [],
        refreshResults: false,
        havePermission: false,
        refreshPermission: false
    },
    mutations: {
        loadEvaluatorData(state, payload){
            state.refreshData = !state.refreshData;
            state.data = payload;
        },
        loadEvaluatorEvaluationsSection(state, payload){
            state.refreshSectionEvaluations = !state.refreshSectionEvaluations;
            state.sectionEvaluations = payload;
        },
        loadEvaluatorEvaluations(state, payload){
            state.refreshEvaluations = !state.refreshEvaluations;

            if(state.evaluations[payload.itinerary] === undefined){
                state.evaluations[payload.itinerary] = [];
            }

            state.evaluations[payload.itinerary] = payload.data;
        },
        loadPermissionEvaluator(state, payload){
            state.refreshPermission = !state.refreshPermission;
            state.havePermission = payload.data;
        },
        loadResultData(state, payload){
            state.refreshResults = !state.refreshResults;
            state.results = payload;
        },
    },
    getters: {
        getterEvaluatorData(state){
            const dummy = state.refreshData; // eslint-disable-line no-unused-vars
            return state.data;
        },
        getterEvaluatorEvaluationsSection(state){
            const dummy = state.refreshSectionEvaluations; // eslint-disable-line no-unused-vars
            return state.sectionEvaluations;
        },
        getterEvaluatorEvaluations(state){
            const dummy = state.refreshEvaluations; // eslint-disable-line no-unused-vars
            return state.evaluations;
        },
        getterEvaluatorsResults(state){
            const dummy = state.refreshResults; // eslint-disable-line no-unused-vars
            return state.results;
        },
        getterPermissionEvaluator(state){
            const dummy = state.refreshPermission; // eslint-disable-line no-unused-vars
            return state.havePermission;
        }
    },
    actions:{
        async getEvaluatorData(state, item){
            await axios.get(
                API_URL + "assign/evaluator/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadEvaluatorData', response.data.data);
                    }
                }
            )
        },
        async getEmptyEvaluatorData(state){
            state.commit('loadEvaluatorData', []);
        },
        async getEvaluatorEvaluationsSectionData(state, item){
            await axios.get(
                API_URL + "assign/evaluator/" + item.id + "/evaluations"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadEvaluatorEvaluationsSection', response.data.data);
                    }
                }
            )
        },
        async getEvaluatorEvaluation(state, item){
            await axios.get(
                API_URL + "assign/evaluator/" + item.id + "/evaluations/" + item.itinerary
            ).then(
                response => {
                    if(response.status === 200) {
                        let params = {
                            'itinerary': item.itinerary,
                            'data': response.data.data
                        }

                        state.commit('loadEvaluatorEvaluations', params);
                    }
                }
            )
        },
        async getPermissionEvaluator(state){
            await axios.get(
                API_URL + "assign/evaluator/permission"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadPermissionEvaluator', response.data);
                    }
                }
            )
        },
        async setEvaluatorData(state, item){
            let requestLogin = item.id ? API_URL + "assign/evaluator/" + item.id : API_URL + "assign/evaluator";
            let formData = new FormData();

            formData.append('evaluator[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200 && item.id === undefined) {
                        router.push({name: 'assign_evaluators'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        destroyedEvaluatorsResults(state){
            state.commit("loadResultData", []);
        },
        async searchEvaluators(state,items){
            let requestLogin = API_URL + 'assign/evaluators';

            let formData = new FormData();

            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit('loadResultData', response.data.data);
                    }
                    return response.status;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
