<template>
    <div style="max-width: 100%; margin-right: auto; margin-left: auto;" :key="'PAGE' + refreshPage">
        <template v-if="$route.matched[0] !== undefined && ($route.matched[0].props.default.showMenu || $route.matched[0].props.default.showEmptyMenu) && isLoggedIn">
            <template v-if="$route.matched[0].props.default.showEmptyMenu && $router.options.GhTabs.length === 0">
                <GhMenu>
                    <template v-slot:cabezera>
                        <div class="logo_cabecera_square">
                            <img :src="require('./assets/main/club-logo.png')" alt="" width="200" style="max-width: unset; max-height: unset; width: 200px;"/>
                        </div>
                        <div class=" T4" style="position: absolute; margin-left: 230px;">
                            {{Login.company.toUpperCase()}}
                        </div>
                    </template>
                    <template v-slot:header-platform_info>

                        <GhUserIcon
                            :generalConfiguration="{ class: 'flex-row-textt', icon: require('./assets/gh_new_dessign/user.svg'), title: '', style: 'top: 53px; right: 20px; position:fixed;', id: 'Icon_User' }"
                            :bodyConfiguration="{ class: 'flex-row-text', title: '', style: '', id: 'Icon_User_Body' }"
                            @acceptChange="changePassword"
                        />
                        <GhHelpIcon
                            :dataBlock="{ icon: require('./assets/gh_new_dessign/help.svg') }"
                        />
                        <GhLanguage v-if="haveData"
                            :dataBlock="{ title: $t('select_language'), id:'select_lang', icon: require('./assets/gh_new_dessign/arrowdown.svg') }"
                            :language="getFeeds.feed_languages_selector"
                            :selected="idioma"
                        ></GhLanguage>
                        <div class="header-phone T-subtitle-second-colored">
                            {{$t('help_phone')+': (+34) xx yyy zz tt'}}
                        </div>
                        <div class="header-email T-subtitle-second-colored">
                            {{$t('help_email')+': notificaciones@globalholdings2000.es'}}
                        </div>
                    </template>
                </GhMenu>
            </template>
            <template v-else-if="$route.matched[0].props.default.showEmptyMenu === undefined">
                <GhMenu ref="refMenu">
                    <template v-slot:cabezera>
                        <div class="logo_cabecera_square">
                            <img :style="Login.environment !== undefined && Login.environment !== null && Login.environment.image !== '' ? '' : 'max-width: unset; max-height: unset; width: 200px;'" :width="Login.environment !== undefined && Login.environment !== null && Login.environment.image !== '' ? '' : '200'" :src="Login.environment !== undefined && Login.environment !== null && Login.environment.image !== '' ? Login.environment.image : require('./assets/main/club-logo.png')" />
                        </div>
                    </template>
                    <template v-slot:header-platform_info>
                        <GhUserIcon
                            :generalConfiguration="{
                                class: 'flex-row-textt',
                                icon: Login.environment !== undefined && Login.environment !== null && Login.environment.pallette !== undefined ? Login.environment.pallette[0] == '#296b31' ? require('./assets/gh_new_dessign/user-pallette-1.svg') : Login.environment.pallette[0] == '#1B82C5' ? require('./assets/gh_new_dessign/user-pallette-2.svg') : require('./assets/gh_new_dessign/user.svg') : require('./assets/gh_new_dessign/user.svg'),
                                title: '',
                                style: 'top: 53px; right: 20px; position:fixed;',
                                id: 'Icon_User'
                            }"
                            :bodyConfiguration="{
                                class: 'flex-row-text',
                                title: '',
                                style: '',
                                id: 'Icon_User_Body'
                            }"
                        />
                        <GhHelpIcon
                            :dataBlock="{
                                icon: Login.environment !== undefined && Login.environment !== null && Login.environment.pallette !== undefined ? Login.environment.pallette[0] == '#296b31' ? require('./assets/gh_new_dessign/help-pallette-1.svg') : Login.environment.pallette[0] == '#1B82C5' ? require('./assets/gh_new_dessign/help-pallette-2.svg') : require('./assets/gh_new_dessign/help.svg') : require('./assets/gh_new_dessign/help.svg')
                            }"
                        />
                        <GhLanguage v-if="haveData"
                            :dataBlock="{
                                title: $t('select_language'),
                                id:'select_lang',
                                icon: Login.environment !== undefined && Login.environment !== null && Login.environment.pallette !== undefined ? Login.environment.pallette[0] == '#296b31' ? require('./assets/gh_new_dessign/arrowdown-pallette-1.svg') : Login.environment.pallette[0] == '#1B82C5' ? require('./assets/gh_new_dessign/arrowdown-pallette-2.svg') : require('./assets/gh_new_dessign/arrowdown.svg') : require('./assets/gh_new_dessign/arrowdown.svg')
                            }"
                            :language="getFeeds.feed_languages_selector"
                            :selected="idioma"
                        ></GhLanguage>
                        <div class="header-phone T-subtitle-second-colored">
                            {{$t('help_phone')+': (+34) xx yyy zz tt'}}
                        </div>
                        <div class="header-email T-subtitle-second-colored">
                            {{$t('help_email')+': notificaciones@globalholdings2000.es'}}
                        </div>
                    </template>
                </GhMenu>
            </template>
            <div style="margin-top: 93px;"></div>
            <GhLocation
                :key="Tabs.TabLoading"
                :dataLocation="{showButton: true, secondWindow: false, showLoader: 'OK', text: $t(Tabs.TabActive)}"
                :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
                @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')"
            />
            <div id="content_page" :class="this.$route.path == '/' ? '' : 'content_page'" v-if="isLoggedIn">
                <router-view/>
                <GhWarningTimeOut
                    :datablock="{
                        time: 1
                    }"
                />

                <component :is="components.popup_401" />
            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>

        <GhDeleteConfirm
            :visible_fields="Global.state.state_confirm.item_confirm"
            :id_popup="'delete_confirm'"
            :content_warning="Global.state.state_confirm.subheader_confirm"
            v-on:function="setDelete"
        />

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_confirm',
                title: $t('required_fields'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t('must_be_follow_fields_complete') + ':'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                    <div class="T15_b" v-for="(item, index) in Global.state.alert_required.requireds" :key="index" style="padding-left: 20px;">
                        {{item}}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_confirm');"
                />
            </template>
        </GhPopUp>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_element',
                title: $t('warning'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t(warning_element_text) + ':'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_element');"
                />
            </template>
        </GhPopUp>

        <component :is="components.warning_save_popup" />
        <component :is="components.warning_content_text" :subTitle="subTitle" :bodyText="bodyText"/>

        <div v-if="Global.state.window_open" class="modal-backdrop"></div>
    </div>
</template>

<script>

    import GhMenu from 'fe-gh-menu-lib';
    import GhLocation from 'fe-gh-location-lib';
    import GhUserIcon from 'fe-gh-user-icon-lib';
    import GhHelpIcon from 'fe-gh-help-lib';
    import GhLanguage from 'fe-gh-language-lib';
    import GhDeleteConfirm from 'fe-gh-delete-confirm-lib';
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhWarningTimeOut from "fe-gh-warning-timeout-lib";

    import {mapGetters, mapState} from "vuex";

    import warning_save_popup from "@/view/shared/popup/warning_save_popup.vue";
    import warning_content_text from "@/view/shared/popup/warning_content_text.vue";
    import popup_401 from "@/view/shared/popup/popup_401.vue";
    import router from "@/routes/router";

    export default {
        components: {
            GhMenu,
            GhLocation,
            GhUserIcon,
            GhHelpIcon,
            GhLanguage,
            GhPopUp,
            GhButton,
            GhWarningTimeOut,
            GhDeleteConfirm
        },
        data(){
            return {
                haveData: true,
                refreshPage: 0,
                idioma: localStorage.getItem('Language-selected-select_lang') ? JSON.parse(localStorage.getItem('Language-selected-select_lang')) : {id: 'es', extra_value_lang: 'Español'},
                components: {
                    warning_save_popup: warning_save_popup,
                    warning_content_text: warning_content_text,
                    popup_401: popup_401
                },
                warning_element_text: '',
                bodyText: '',
                subTitle: ''
            }
        },
        watch:{
            async '$i18n.locale'(data){
                await this.$store.dispatch('setChangeLanguage', {lang: data}, {root: true});
                this.Global.changeLanguage();
                this.resizeMenu();
            },
            async 'Tabs.TabActive'() {
                await this.$store.dispatch('resetAppTabs', '', {root: true});

                if(this.Login.initial_to_general){
                    this.$store.dispatch('initialToGeneral', false);

                    if(this.Login.view_initial){
                        this.$store.dispatch('viewInitial',false);

                        var id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id']

                        this.activeTabCorrect('C-' + id_redirect,'my_itineraries');
                        this.slideBarCorrect(id_redirect);
                    }

                    this.refreshPage++;
                }
            },
            async 'Login.token'(data){
                if(data !== undefined) {
                    await this.$store.dispatch('getLanguagesData', '', {root: true});
                    await this.$store.dispatch('resetAppTabs', '', {root: true});
                    await this.$store.dispatch('getEnvironmentStyles', '', {root: true});
                }
            }
        },
        async beforeMount(){
            this.checkPermissions();
            this.checkNavigation();
            // if(this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu){
            //     await this.$store.dispatch('getLanguagesData', '',{root:true});
            // }
            this.haveData = true;
            this.refreshPage++;
        },
        async mounted() {
            this.$i18n.locale = this.idioma.id;
            await this.updateBackColor();
            this.checkPermissions();
            this.refreshPage++;
        },
        async beforeUpdate() {
            this.resizeMenu();
            await this.updateBackColor();
            this.checkPermissions();
        },
        computed: {
            ...mapState(["TabItem","TabCustom","TabHeader",'Tabs','getFeeds','Login']),
            ...mapGetters(['isLoggedIn'])
        },
        methods:{
            slideBarCorrect(id){
                let maximoaumento = document.getElementById(`C-${id}`).getBoundingClientRect().width;
                let destino = document.getElementById('C-'+id).getBoundingClientRect().x;
                document.getElementById('nav-actived').getElementsByClassName('nav-active-center')[0].animate([{width: maximoaumento-10+'px'},], {duration: 180, fill:'forwards'});
                document.getElementById('nav-actived').animate([{left: destino+'px'},], {duration: 180, fill:'forwards'});
            },
            activeTabCorrect(id,value){
                this.id = id;
                localStorage.setItem('item', value)
                document.getElementById('text_tab_location').innerHTML = this.$t(value);
                this.$router.options.GhTabs.forEach((items) => {
                    document.getElementById(`C-${items.id}`).className = "nav-element  link T1 tab-inactive"
                });

                localStorage.setItem('TabItem',  value);

                let destino = 4;
                let time = 0;
                var url = '';
                let TimeWhait = 2;

                this.$store.commit("LOAD_TAB", '');

                if(this.$route.path == '/'+this.$store.state.Tabs.TabActive){
                    document.getElementById('content_page').style.display = 'none';
                }

                if(this.$store.state.Tabs.TabActive == ''){
                    this.$store.commit("LOAD_TAB", 'OK');
                }

                if (TimeWhait < destino) {
                    time = setInterval( () => {
                        if (TimeWhait >= destino) {
                            clearInterval(time);
                            this.$store.dispatch("GhTabActive",[{id:id},{value:value}] ,{root:true});
                            this.$router.options.routes.forEach((items) => {
                                if(items['name'] == this.$store.state.Tabs.TabActive){
                                    url = items['path'];
                                }
                            });

                            if(url != '') {
                                this.$router.replace(localStorage.getItem('RedirectPerm') ? localStorage.getItem('RedirectPerm') : url).catch(() => {});
                                localStorage.getItem('RedirectPerm') ? localStorage.removeItem('RedirectPerm') : '';
                                window.scroll(0, 0);
                                this.$store.commit("LOAD_TAB", 'OK');
                                document.getElementById('content_page').style.display = '';
                            }
                        }

                        if (TimeWhait < destino) {
                            TimeWhait += 1;
                        }
                    }, 15);
                }
                localStorage.setItem('id', id);
            },
            checkNavigation(){
                const isFirefox = typeof InstallTrigger !== 'undefined';

                var r = document.querySelector(':root');

                if (isFirefox) {
                    r.style.setProperty('--white-space','preserve-spaces');
                }else{
                    r.style.setProperty('--white-space','normal');
                }
            },
            resizeMenu(){
                var that = this;
                setTimeout(function (){
                    if(that.$refs.refMenu !== undefined) {

                        if(that.Login.environment !== undefined && that.Login.environment !== null && that.Login.environment.image === '') {
                            that.$refs.refMenu.$children[3].$el.children[0].style.marginLeft = '250px';
                        }

                        var tabs = that.$refs.refMenu.$children[3].$el.children[0].children;
                        for (var i = 0; i < tabs.length; i++) {
                            if (i != 0) {
                                let NumLetters = that.splitResizeTab(tabs[i].lastChild.textContent).split('\n').reduce(function (a, b) {
                                    return a.length > b.length ? a : b;
                                }).length;

                                let name = '';
                                tabs[i].lastChild.textContent.split('\n').forEach((i) => {
                                    name += i + ' ';
                                });

                                if(name.trim() === that.$t('todo')){
                                    NumLetters = 8;
                                }

                                const CalculateWidth = 8.67 * NumLetters;
                                that.$refs.refMenu.$children[3].$el.children[0].children[i].lastChild.style.width = CalculateWidth + 'px';
                                if (tabs[i].classList[3] == 'tab-active') {
                                    let maximoaumento = document.getElementById(tabs[i].id).getBoundingClientRect().width;
                                    let destino = document.getElementById(tabs[i].id).getBoundingClientRect().x;
                                    document.getElementById('nav-actived').getElementsByClassName('nav-active-center')[0].animate([{width: maximoaumento - 10 + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                    document.getElementById('nav-actived').animate([{left: destino + 'px'},], {
                                        duration: 0,
                                        fill: 'forwards'
                                    });
                                }
                            }
                        }
                    }
                },150);
            },
            splitResizeTab(value){
                var stringnosplited = value.split(' ');
                var stringsplited = '';
                var stringsplitedaux = '';
                stringnosplited.forEach((string,i)=>{
                    if(i == 0){
                        stringsplited = string;
                        stringsplitedaux = string;
                    }else{
                        stringsplitedaux += string;
                        if(stringsplitedaux.length < 12) {
                            stringsplited += ' ' + string;
                        }else{
                            stringsplited+= '\n' + string;
                        }
                    }
                })
                return stringsplited;
            },
            updateBackColor(){
                var r = document.querySelector(':root');
                var color = '#FFFFFF';
                var colorPallette1 = '';
                var colorPallette2 = '';
                var colorPallette3 = '';
                var colorPallette4 = '';
                var colorPallette5 = '';
                var colorPallette6 = '';
                var colorPalletteActiveBisel1 = 'url(\'./assets/gh_new_dessign/tab_1_active.svg\')';
                var colorPalletteActiveBisel2 = 'url(\'./assets/gh_new_dessign/tab_2_active.svg\')';
                var colorPalletteActiveBisel3 = 'url(\'./assets/gh_new_dessign/tab_3_active.svg\')';

                if((this.$route.matched[0] !== undefined && this.$route.matched[0].props !== undefined && (this.$route.matched[0].props.default.showMenu || this.$route.matched[0].props.default.background)) || (this.$route.matched[0].props.default.showEmptyMenu && this.$router.options.GhTabs.length === 0)){
                    color = '#DDDDDD';
                    if(this.Login.environment !== undefined && this.Login.environment !== null && this.Login.environment.pallette !== undefined){
                        colorPallette1 = this.Login.environment.pallette[0];
                        colorPallette2 = this.Login.environment.pallette[1];
                        colorPallette3 = this.Login.environment.pallette[2];
                        colorPallette4 = this.Login.environment.pallette[3];
                        colorPallette5 = this.Login.environment.pallette[4];
                        colorPallette6 = this.Login.environment.pallette[5];

                        if(this.Login.environment.pallette[0] == '#296b31') {
                            colorPalletteActiveBisel1 = 'url(\'./assets/gh_new_dessign/tab_1_active.svg\')';
                            colorPalletteActiveBisel2 = 'url(\'./assets/gh_new_dessign/tab_2_active.svg\')';
                            colorPalletteActiveBisel3 = 'url(\'./assets/gh_new_dessign/tab_3_active.svg\')';
                        }
                    }

                }


                if(this.$route.matched[0] !== undefined && this.$route.matched[0].props !== undefined && this.$route.matched[0].props.default.questionnaireBackground !== undefined && this.$route.matched[0].props.default.questionnaireBackground){
                    colorPallette6 = '#f9f9f9';
                }

                r.style.setProperty('--body_color',colorPallette6 !== '' ? colorPallette6 : color);
                r.style.setProperty('--pallette-one', colorPallette1);
                r.style.setProperty('--pallette-two', colorPallette2);
                r.style.setProperty('--pallette-three', colorPallette3);
                r.style.setProperty('--pallette-four', colorPallette4);
                r.style.setProperty('--pallette-five', colorPallette5);
                r.style.setProperty('--pallette-bisel-active-left', colorPalletteActiveBisel1);
                r.style.setProperty('--pallette-bisel-active-center', colorPalletteActiveBisel2);
                r.style.setProperty('--pallette-bisel-active-right', colorPalletteActiveBisel3);
            },
            setDelete(){
                this.$store.dispatch('remove'+this.Global.state.state_confirm.where_confirm,{
                  id: this.Global.state.state_confirm.id_delete_confirm,
                  type: this.Global.state.state_confirm.type_confirm
                },{root:true});
                this.Global.closePopUp('delete_confirm');
            },
            checkPermissions(){
                if(this.$route.matched !== undefined && this.$route.matched[0] !== undefined && this.$route.matched[0].props !== undefined && this.$route.matched[0].props.default.showEmptyMenu){
                    this.$router.options.GhTabs = [];
                }

                if(this.$route.matched !== undefined && this.$route.matched[0] !== undefined && this.$route.matched[0].props !== undefined && this.$route.matched[0].props.default.showMenu) {
                    this.$router.options.GhTabs.forEach((route, keyRoute) => {
                        this.Login.tabs.forEach((permRoute) => {
                            if (route.value == permRoute.name && !permRoute.have_permission) {
                                delete this.$router.options.GhTabs[keyRoute];
                            }


                            if (permRoute.have_permission && permRoute.name == 'searcher') {
                                permRoute['sub-modules'].forEach((subModuleExplotation) => {
                                    this.$router.options.GhSubTabsSearch.filter((routeExplotation, keyExplotation) => {
                                        if (routeExplotation.value == subModuleExplotation.name && !subModuleExplotation.have_permission) {
                                            delete this.$router.options.GhSubTabsSearch[keyExplotation];
                                        }
                                    });
                                });
                            }

                            if (permRoute.have_permission && permRoute.name == 'configuration') {
                                permRoute['sub-modules'].forEach((subModuleConfiguration) => {
                                    this.$router.options.GhSubTabsConfiguration.filter((routeConfiguration, keyConfiguration) => {
                                        if (routeConfiguration.value === subModuleConfiguration.name && !subModuleConfiguration.have_permission) {
                                            delete this.$router.options.GhSubTabsConfiguration[keyConfiguration];
                                        }
                                    });
                                });
                            }

                            if (permRoute.have_permission && permRoute.name == 'my_itineraries') {
                                permRoute['sub-modules'].forEach((subModulePrograms) => {
                                    this.$router.options.GhSubTabsMyItinerary.filter((routePrograms, keyPrograms) => {
                                        if (routePrograms.value === subModulePrograms.name && !subModulePrograms.have_permission) {
                                            delete this.$router.options.GhSubTabsMyItinerary[keyPrograms];
                                        }
                                    });
                                });
                            }

                            if (permRoute.have_permission && permRoute.name == 'perms') {
                                permRoute['sub-modules'].forEach((subModulePerms) => {
                                    this.$router.options.GhSubTabsPerms.filter((routePerms, keyPerms) => {
                                        if (routePerms.value === subModulePerms.name && !subModulePerms.have_permission) {
                                            delete this.$router.options.GhSubTabsPerms[keyPerms];
                                        }
                                    });
                                });
                            }

                            if (permRoute.have_permission && permRoute.name == 'base_creation') {
                                permRoute['sub-modules'].forEach((subModuleBaseCreation) => {
                                    this.$router.options.GhSubTabsBaseCreation.filter((routeBaseCreation, keyBaseCreation) => {
                                        if (routeBaseCreation.value === subModuleBaseCreation.name && !subModuleBaseCreation.have_permission) {
                                            delete this.$router.options.GhSubTabsBaseCreation[keyBaseCreation];
                                        }
                                    });
                                });
                            }

                            if (permRoute.have_permission && permRoute.name == 'create') {
                                permRoute['sub-modules'].forEach((subModuleExplotation) => {
                                    this.$router.options.GhSubTabsCreation.filter((routeExplotation, keyExplotation) => {
                                        if (routeExplotation.value == subModuleExplotation.name && !subModuleExplotation.have_permission) {
                                            delete this.$router.options.GhSubTabsCreation[keyExplotation];
                                        }
                                    });
                                });
                            }
                        });
                    });

                    this.$router.options.GhTabs = this.$router.options.GhTabs.filter(function (item) {
                        return item !== undefined;
                    });

                    this.$router.options.GhSubTabsSearch = this.$router.options.GhSubTabsSearch.filter(function (item) {
                        return item !== undefined;
                    });

                    this.$router.options.GhSubTabsConfiguration = this.$router.options.GhSubTabsConfiguration.filter(function (item) {
                        return item !== undefined;
                    });

                    this.$router.options.GhSubTabsMyItinerary = this.$router.options.GhSubTabsMyItinerary.filter(function (item) {
                        return item !== undefined;
                    });

                    this.$router.options.GhSubTabsPerms = this.$router.options.GhSubTabsPerms.filter(function (item) {
                        return item !== undefined;
                    });

                    this.$router.options.GhSubTabsCreation = this.$router.options.GhSubTabsCreation.filter(function (item) {
                        return item !== undefined;
                    });
                }
            },
            async changePassword(){
                const formData = new FormData(document.getElementById('change_user_password'));
                var response = await this.$store.dispatch('changeUserPassword',{
                    formData: [...formData]
                },{root:true});

                if(response.data.error !== undefined) {
                    var bodyValues = new Array();
                    bodyValues.push(response.data.error);
                    this.Global.state.alert_required.requireds = bodyValues;
                    this.Global.openPopUp('warning_confirm');
                }else {
                    this.Global.closePopUp('reset_password_popup');
                    this.Global.closePopUp('Icon_User_popup');
                }
            },
        },
    }
</script>

<style>
    @import './assets/css/styles.css';
    @import "~fe-gh-drop-down-field-lib/dist/fe-gh-drop-down-field-lib.css";
    @import '~fe-gh-menu-lib/dist/fe-gh-menu-lib.css';
    @import '~fe-gh-tabs-lib/dist/fe-gh-tabs-lib.css';
    @import '~fe-gh-location-lib/dist/fe-gh-location-lib.css';
    @import '~fe-gh-user-icon-lib/dist/fe-gh-user-icon-lib.css';
    @import "~fe-gh-popup-lib/dist/fe-gh-popup-lib.css";
    @import '~fe-gh-button-lib/dist/fe-gh-button-lib.css';
    @import "~fe-gh-inputtextfield-lib/dist/fe-gh-inputtextfield-lib.css";
    @import "~fe-gh-help-lib/dist/fe-gh-help-lib.css";
    @import "~fe-gh-language-lib/dist/fe-gh-language-lib.css";
    @import "~fe-gh-checks-lib/dist/fe-gh-checks-lib.css";
    @import "~fe-gh-table-common-lib/dist/fe-gh-table-common-lib.css";
    @import "~fe-gh-subtab-lib/dist/fe-gh-subtab-lib.css";
    /*@import './assets/css/data-selector-table.css';*/
    @import "./assets/css/table-common.css";
    @import "~fe-gh-chart-radar-lib/dist/fe-gh-chart-radar-lib.css";
    @import "./assets/css/chart-table.css";
    @import "./assets/css/selector-payment.css";
    @import "~fe-gh-dropselector-lib/dist/fe-gh-dropselector-lib.css";
    @import "~fe-gh-delete-confirm-lib/dist/fe-gh-delete-confirm-lib.css";
    @import "~fe-gh-tree-action-block-lib/dist/fe-gh-tree-action-block-lib.css";
    @import "./assets/css/tree-action.css";
    @import "~fe-gh-input-hour-lib/dist/fe-gh-input-hour-lib.css";
    @import "~fe-gh-textarea-lib/dist/fe-gh-textarea-lib.css";
    @import "~fe-gh-quiz-lib/dist/fe-gh-quiz-lib.css";
    @import "~fe-gh-easy-upload-lib/dist/fe-gh-easy-upload-lib.css";
    @import "~fe-gh-color-lib/dist/fe-gh-color-lib.css";

    :root{
        --white-space: normal;
        --body_color: #FFFFFF;
        --pallette-one: #0b4683;
        --pallette-two: #b3dbff;
        --pallette-three: #d9edfc;
        --pallette-four: #C5D1DF;
        --pallette-five: #84A0B7;
        --pallette-bisel-active-left: url('./assets/gh_new_dessign/tab_1_active.svg');
        --pallette-bisel-active-center: url('./assets/gh_new_dessign/tab_2_active.svg');
        --pallette-bisel-active-right: url('./assets/gh_new_dessign/tab_3_active.svg');
    }

    .tab-active, .subtitulo, .nav-active-background, .modal-header, .checkmark_checked{
        background-color: var(--pallette-one) !important;
    }

    .tab-inactive > .tab-text{
        color: var(--pallette-one) !important;
    }

    .tab-inactive:hover > div{
        color: var(--pallette-one) !important;
    }

    .T1, .T4, .T9, .T15_action, .T19, .C7, .C1, .F2, .T10, .T10_b, .T14, .container-md_button_content, .link{
        color: var(--pallette-one) !important;
    }

    .container-md_button_content{
        border: 1px solid var(--pallette-one) !important;
    }

    .collapsible > svg > .cls-2{
        fill: var(--pallette-one) !important;
    }

    .inner_radio-checked{
        background-color: var(--pallette-one) !important;
    }

    .collapsible_section-header  .label{
        background-color: var(--pallette-two) !important;
    }

    .collapsible_section-header .extra_label{
        background-color: var(--pallette-three) !important;
    }

    .striped, .striped_tree{
        background-color: var(--pallette-three) !important;
    }

    .collapsible_expanded{
        border-top: 1px solid var(--pallette-three) !important;
    }

    .table-header, .table-footer, .section_line{
        background-color: var(--pallette-four);
    }

    /* TODO */

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    * {
        padding: 0;
        margin: 0;
    }

    body{
        background-color: var( --body_color);
    }

    .content_page{
        padding-left: 12px;
        padding-right: 12px;
    }

    .gh_text_field-label {
        height: 14px;
        min-width: 10px !important;
        margin-left: 0px;
        margin-right: 10px;
        text-align: left;
        white-space: nowrap;
        cursor: default;
    }

    .gh_text_field-label{
        white-space: var(--white-space) !important;
        height: auto !important;
    }

    .modal-header > div{
        font-size: 18px!important;
        font-family: "PT Sans B" !important;
    }
</style>
