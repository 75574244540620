<template>
    <div>
        <div class="gh-content-global">
            <form id="form_search_evaluators" :key="'FORM_SEARCH_EVALUATORS' + refresh">
                <div class="gh-row-line-flex">
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('name'),
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_name',
                                name: 'evaluators[name]',
                                style: 'width:60%',
                                value: persistance.name,
                                type: 'text'
                            }"
                            ref="refName"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('surnames'),
                                style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_surname',
                                name: 'evaluators[surname]',
                                style: 'width:60%',
                                value: persistance.surname,
                                type: 'text'
                            }"
                            ref="refSurname"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('nif_nie'),
                                style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_nif',
                                name: 'evaluators[nif]',
                                style: 'width:20%; min-width: 100px;',
                                value: persistance.nif,
                                type: 'text'
                            }"
                            ref="refNif"
                        />
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <div class="children-contents" style="width: 33.33%; display: flex">
                        <GhCalendar
                            :datalabel="{
                                id: 'label_evaluators_incorporation',
                                text: $t('incorporation'),
                                style: 'width: 160px; min-width: 160px; text-align: right; margin-right: 12px;'
                            }"
                            :datainput="{
                                id: 'evaluators_incorporation',
                                name: 'evaluators[incorporation]',
                                value: '',
                                required: false,
                                format: 'DD/MM/YYYY',
                                placeholder: 'DD/MM/YYYY'
                            }"
                        />
                    </div>
                    <div style="width: 66.66%; display: flex">
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; margin-left: 20px; width: 150px; min-width: 150px; text-align: right;">{{$t('available')}}</label>
                        <GhChecks
                            :datalabel="{
                                text: $t('yes'),
                                style: '',
                                class: '',
                                id: ''
                            }"
                            :datainput="{
                                required: true,
                                selected: '',
                                type: 'radio',
                                id: 'evaluators_available_yes',
                                value: 1,
                                style: '',
                                name: 'evaluators[available]'
                            }"
                        />
                        <GhChecks
                            :datalabel="{
                                text: $t('no'),
                                style: '',
                                class: '',
                                id: ''
                            }"
                            :datainput="{
                                required: true,
                                selected: '',
                                type: 'radio',
                                id: 'evaluators_available_no',
                                value: 2,
                                style: '',
                                name: 'evaluators[available]'
                            }"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('full_address'),
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_full_address',
                                name: 'evaluators[full_address]',
                                style: 'width:60%',
                                value: persistance.full_address,
                                type: 'text'
                            }"
                            ref="refFullAddress"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('locality'),
                                style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_locality',
                                name: 'evaluators[locality]',
                                style: 'width:60%',
                                value: persistance.locality,
                                type: 'text'
                            }"
                            ref="refLocality"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('cp'),
                                style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_cp',
                                name: 'evaluators[cp]',
                                style: 'width:20%; min-width: 100px;',
                                value: persistance.cp,
                                type: 'text'
                            }"
                            ref="refCp"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_country',
                                class: 'T15_b',
                                subtitle: $t('select_country') + ':',
                                text: $t('country'),
                                label: $t('country'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_country',
                                name: 'evaluators[country]'
                            }"
                            :feed="getFeeds.feed_country"
                            :selected_input="getFeeds.feed_ho_is_spain"
                            @accept="searchCommunity"
                            ref="refCountry"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_community',
                                class: 'T15_b',
                                subtitle: $t('select_community') + ':',
                                text: $t('community'),
                                label: $t('community'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluators_community',
                                name: 'evaluators[community]'
                            }"
                            :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                            :selected_input="[]"
                            @accept="searchProvince"
                            ref="refCommunity"
                            :key="'refresh_community_' + refreshCommunity"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_province',
                                class: 'T15_b',
                                subtitle: $t('select_province') + ':',
                                text: $t('province'),
                                label: $t('province'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_province',
                                name: 'evaluators[province]'
                            }"
                            :feed="getterProvinceByCommunity !== undefined ? getterProvinceByCommunity : []"
                            :selected_input="[]"
                            ref="refProvince"
                            :key="'refresh_province_' + refreshProvince"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('position'),
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_position',
                                name: 'evaluators[position]',
                                style: 'width:60%',
                                value: persistance.position,
                                type: 'text'
                            }"
                            ref="refPosition"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('telephone'),
                                style:'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_telephone',
                                name: 'evaluators[telephone]',
                                style: 'width:60%',
                                value: persistance.telephone,
                                type: 'text'
                            }"
                            ref="refTelephone"
                        />
                    </div>
                    <div class="gh-row-column">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('email'),
                                style:'margin-left:20px; min-width: 150px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: false,
                                id: 'evaluators_email',
                                name: 'evaluators[email]',
                                style: 'width:60%',
                                value: persistance.email,
                                type: 'text'
                            }"
                            ref="refEmail"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_category_club',
                                class: 'T15_b',
                                subtitle: $t('select_category_club') + ':',
                                text: $t('category_club'),
                                label: $t('category_club'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_category_club',
                                name: 'evaluators[category_club]'
                            }"
                            :feed="getFeeds.feed_categories_club"
                            :selected_input="[]"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_type_evaluation',
                                class: 'T15_b',
                                subtitle: $t('select_type_evaluation') + ':',
                                text: $t('type_evaluation'),
                                label: $t('type_evaluation'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluators_type_evaluation',
                                name: 'evaluators[type_evaluation]'
                            }"
                            :feed="getFeeds.feed_type_evaluation"
                            :selected_input="[]"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_ccaa_where_work',
                                class: 'T15_b',
                                subtitle: $t('select_ccaa_where_work') + ':',
                                text: $t('ccaa_where_work'),
                                label: $t('ccaa_where_work'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_ccaa_where_work',
                                name: 'evaluators[ccaa_where_work]'
                            }"
                            :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                            :selected_input="[]"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_evaluator_modality',
                                class: 'T15_b',
                                subtitle: $t('select_evaluator_modality') + ':',
                                text: $t('evaluator_modality'),
                                label: $t('evaluator_modality'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_evaluator_modality',
                                name: 'evaluators[evaluator_modality]'
                            }"
                            :feed="getFeeds.feed_evaluator_modality"
                            :selected_input="[]"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_professional_activity_sector',
                                class: 'T15_b',
                                subtitle: $t('select_professional_activity_sector') + ':',
                                text: $t('professional_activity_sector'),
                                label: $t('professional_activity_sector'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluators_professional_activity_sector',
                                name: 'evaluators[professional_activity_sector]'
                            }"
                            :feed="getFeeds.feed_professional_activity_sector"
                            :selected_input="[]"
                        />
                    </div>
                    <div class="gh-row-column no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluators_grade_experience',
                                class: 'T15_b',
                                subtitle: $t('select_grade_experience') + ':',
                                text: $t('grade_experience'),
                                label: $t('grade_experience'),
                                type: 'radio',
                                columns: 3,
                                required: false,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluators_grade_experience',
                                name: 'evaluators[grade_experience]'
                            }"
                            :feed="getFeeds.feed_grade_experience"
                            :selected_input="[]"
                        />
                    </div>
                </div>
                <div class="gh-row-line">
                    <GhDataSelector
                        :datablock="{
                            id: 'evaluators_professional_experience_sector',
                            class: 'T15_b',
                            subtitle: $t('select_professional_experience_sector') + ':',
                            text: $t('professional_experience_sector'),
                            label: $t('professional_experience_sector'),
                            type: 'radio',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_evaluators_professional_experience_sector',
                            name: 'evaluators[professional_experience_sector]'
                        }"
                        :feed="getFeeds.feed_professional_experience_sector"
                        :selected_input="[]"
                    />
                </div>
                <div class="gh-row-content">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'search[criterio]',
                            style: 'margin-left:35px;',
                            selected: true
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            name: 'search[criterio]',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                    />
                </div>
            </form>
        </div>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('search')}" @click="search()"/>
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()"/>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'search_evaluators',
        components: {
            GhInputTextField,
            GhCalendar,
            GhChecks,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterProvinceByCommunity', 'getterCommunityByCountry', 'getterEvaluatorsResults']),
        },
        data(){
            return {
                refreshProvince: 0,
                refreshCommunity: 0,
                refresh: 0,
                persistance: {
                    name: '',
                    surname: '',
                    cif: '',
                    full_address: '',
                    locality: '',
                    cp: '',
                    position: '',
                    telephone: '',
                    email: ''
                }
            }
        },
        watch: {
            'getterCommunityByCountry'(){
                this.refreshCommunity++;
            },
            'getterProvinceByCommunity'(){
                this.refreshProvince++;
            },
        },
        async beforeMount(){
            await this.$store.dispatch('getHoIsSpain', '', {root: true});
            await this.$store.dispatch('getCountry', '', {root:true});
            await this.$store.dispatch('getFeedCategoryClub', '', {root: true});
            await this.$store.dispatch('getFeedTypeEvaluation', '', {root: true});
            await this.$store.dispatch('getFeedEvaluatorModality', '', {root: true});
            await this.$store.dispatch('getProfessionalActivitySectorFeed', '', {root: true});
            await this.$store.dispatch('getGradeExperienceData', '', {root: true});
            await this.$store.dispatch('getProfessionalExperienceSectorFeedData', '', {root: true});
        },
        methods: {
            searchProvince(){
                this.persist();
                var community = this.$refs.refCommunity.checkdata;
                community = Object.keys(community);

                if(community.length !== 0){
                    community = community[0];
                    this.$store.dispatch('getProvinceByCommunity', {
                        community: community,
                    }, {root: true});
                }
            },
            searchCommunity(){
                this.persist();
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getCommunityByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            persist(){
                this.persistance.name = this.$refs['refName'].datainput.value;
                this.persistance.surname = this.$refs['refSurname'].datainput.value;
                this.persistance.cif = this.$refs['refNif'].datainput.value
                this.persistance.full_address = this.$refs['refFullAddress'].datainput.value
                this.persistance.locality = this.$refs['refLocality'].datainput.value
                this.persistance.cp = this.$refs['refCp'].datainput.value
                this.persistance.position = this.$refs['refPosition'].datainput.value
                this.persistance.telephone = this.$refs['refTelephone'].datainput.value
                this.persistance.email = this.$refs['refEmail'].datainput.value
            },
            async search(){
                this.$parent.$parent.showSectionResults = true;
                this.$parent.$parent.loaderResults = true;

                const formData = new FormData(document.getElementById('form_search_evaluators'));

                await this.$store.dispatch('searchEvaluators', {
                    formData: [...formData]
                }, {root: true}).then((response) => {
                    if(response === 200) {
                        this.$parent.$parent.loaderResults = false;
                    }
                });
            },
            clean(){
                this.$parent.$parent.showSectionResults = false;
                this.$store.dispatch('destroyedEvaluatorsResults','',{root:true});
                document.getElementById("form_search_evaluators").reset();
                this.refresh++;
            }
        }
    }
</script>

<style>
    #evaluators_country_selector_label, #evaluators_category_club_selector_label, #evaluators_evaluator_modality_selector_label, #evaluators_professional_experience_sector_selector_label{
        width: 160px !important;
        text-align: right;
    }
    #evaluators_community_selector_label, #evaluators_province_selector_label, #evaluators_type_evaluation_selector_label, #evaluators_ccaa_where_work_selector_label, #evaluators_professional_activity_sector_selector_label, #evaluators_grade_experience_selector_label{
        width: 150px !important;
        text-align: right;
    }
</style>