import ConfigClubExperience from "@/view/config/evaluators/club_experience/template/club_experience.vue";

const routes = [
    {
        path: '/config/creation/club-experience/:id',
        name: 'config_club_experience_id',
        component: ConfigClubExperience,
        props: {id: null, showMenu: true, otherTitle: 'club_experience'},
    }
];

export default routes;